import { Component, OnInit } from '@angular/core';
import { CommonBindingDataService } from '../../modules/shared/services/common-binding-data.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Message, ConfirmationService } from 'primeng/components/common/api';
import { AppSettings } from 'app/modules/shared/app.settings';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-authorize-card-details',
  templateUrl: './authorize-card-details.component.html',
  styleUrls: ['./authorize-card-details.component.scss']
})
export class AuthorizeCardDetailsComponent implements OnInit {

  walletTopupForm: FormGroup;
  message: Message[] = [];
  authorizationKey;
  cardNumber;


  constructor(
    private commonBindingDataService: CommonBindingDataService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,

  ) { }

  ngOnInit() {

    this.walletTopupForm = this.formBuilder.group({
      cardNumber: ['', [
        // Validators.required,
        // Validators.minLength(1),
        // Validators.maxLength(50),
        // Validators.pattern(AppSettings.CARD_NUMBER_PATTERN)
      ]],
      amount: ['', [
        Validators.required,
        // Validators.minLength(1),
        // Validators.maxLength(5),
        Validators.pattern(AppSettings.AMOUNT_PATTERN)
      ]],
      cardCode: ['', [
        Validators.required,
        // Validators.minLength(1),
        Validators.maxLength(4),
        // Validators.pattern(AppSettings.NAME_PATTERN)
      ]],
  
    
    
    });

    this.authorizationKey = this.route.snapshot.queryParams['key'];
    this.cardNumber = this.route.snapshot.queryParams['card-number'];
    // this.walletTopupForm.controls.cardNumber.setValue(this.cardNumber);
    this.getCardDetails();
  }


  getCardDetails() {
    this.commonBindingDataService.getAuthorizedCardDetails(this.authorizationKey).subscribe((result) => {
      this.cardNumber = result.cardNumber;
      this.walletTopupForm.controls.cardNumber.setValue(this.cardNumber);
    }, (reason) => {  
      this.showMessage('Error',reason.general[0].message);

    });
  }
  
  addNow() {
    const data={
      "amount":(this.walletTopupForm.controls.amount.value.trim()).replace(/\s/g, ""),
      "cardCode": (this.walletTopupForm.controls.cardCode.value).replace(/\s/g, ""),

    };
    this.commonBindingDataService.walletRecharge(data,this.authorizationKey).subscribe((result) => {
      this.showMessage('Success',result.message);
      this.walletTopupForm.reset();
      setTimeout(() => {
      this.router.navigate(['authorize-dot-net/success-page']);
    }, 2000);


    }, (reason) => {  
      this.showMessage('Error',reason.general[0].message);

    });
  }

 
  showMessage(severity, message) {
    this.message = [];
    this.message.push({ severity: severity.toLowerCase(), summary: severity, detail: message });
  }

}
