import { ActivatedRoute } from '@angular/router';
import { BookingService } from './../../../bookings/services/booking.service';
import { AppSettings } from './../../app.settings';
import { UploadQuoteComponent } from './../upload-quote/upload-quote.component';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CommonBindingDataService } from './../../../shared/services/common-binding-data.service';
import { Message, ConfirmationService } from 'primeng/components/common/api';
import { SalesQuotationBookingDetailsModal } from './../../../sales-quotation/modal/sales-quotation-booking-details-modal';
import { SalesQuotationBookingDetailsService } from './../../../sales-quotation/services/sales-quotation-booking-details.service';


@Component({
  selector: 'app-trip-details',
  templateUrl: './trip-details.component.html',
  providers: [BookingService]
})
export class TripDetailsComponent implements OnInit, OnChanges {
  @Input() tripDetails = null;
  @Output() onStatusChange: EventEmitter<any> = new EventEmitter();
  @ViewChild(UploadQuoteComponent, { static: false }) child: UploadQuoteComponent;

  quotationBaseURL = AppSettings.GET_FILE_THUMB_URL;
  salesQuatationId: any;
  message: Message[] = [];
  tripEndData;

  resendQuote;
  uploadQuote;
  deliveryCheck;
  securityCheck;
  cancelReasonText;
  cancelBooking;
  carReturnCheck;
  returnSecurityCheck;
  extendTrip;
  extendTripHeader;
  enterOtp;
  currentKM;

  startTrip;
  endTrip;
  cancelTrip;
  hour;
  mins;

  OTP = false;
  display = false;
  displayCancelTrip = false;
  cancelQuotationView = false;
  securityCheckOTP = false;
  deliveryCheckStartKM = false;
  carReturnCheckEndTrip = false;
  returnSecurityCheckEndKM = false;
  extendTripConfirm = false;


  constructor(private commonService: CommonBindingDataService,
    private bookingService: BookingService,
    private salesQuotationBookingDetailsService: SalesQuotationBookingDetailsService,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService, ) { }

  ngOnInit() {
    this.cancelBooking = this.commonService.getLabel('label_cancel_booking');
    this.resendQuote = this.commonService.getLabel('label_resend_quote');
    this.uploadQuote = this.commonService.getLabel('label_upload_quote');
    this.startTrip = this.commonService.getLabel('label_start_trip');
    this.endTrip = this.commonService.getLabel('label_end_trip');
    this.cancelTrip = this.commonService.getLabel('label_cancel_trip');
    this.securityCheck = this.commonService.getLabel('label_security_check');
    this.deliveryCheck = this.commonService.getLabel('label_delivery_check');
    this.carReturnCheck = this.commonService.getLabel('label_car_return_check');
    this.returnSecurityCheck = this.commonService.getLabel('label_return_security_check');
    this.extendTrip = this.commonService.getLabel('label_extend_trip');
    this.extendTripHeader = this.commonService.getLabel('label_extend_package_confirmation_header');
    this.enterOtp = this.commonService.getLabel('label_otp');
    this.currentKM = this.commonService.getLabel('label_current_km');
  }

  ngOnChanges(samplechange: SimpleChanges) {
    if (this.tripDetails !== undefined && this.tripDetails !== null) {
      if (this.tripDetails.packageName !== undefined && this.tripDetails.packageName !== null) {
        this.hour = parseInt(this.tripDetails.minutes, 10);
        this.mins = this.hour % 60;
        this.hour = this.hour / 60;
        this.hour = parseInt(this.hour, 10);
      }
      this.salesQuatationId = this.tripDetails.quotationId;
    }
  }

  onStartTrip() {
    this.OTP = true;
  }

  onSecurityCheck() {
    this.securityCheckOTP = true;
  }

  onDeliveryCheck() {
    this.deliveryCheckStartKM = true;
  }

  onCarReturnCheck() {
    this.carReturnCheckEndTrip = true;
  }

  onReturnSecurityCheck() {
    this.returnSecurityCheckEndKM = true;
  }

  onExtendTrip() {
    this.confirmationService.confirm({
      message: this.commonService.getLabel('label_confirm_extend_package'),
      accept: () => {
        this.commonService.extendPackage(this.tripDetails.id).subscribe(results => {
          this.onStatusChange.emit();
          if (results.general !== undefined) {
            this.displayMessage(results, 'success');
          }
        }, (error) => {

          this.displayMessage(error, 'error');
        });
      }
    });
  }

  onEndTrip() {
    this.tripEndData = {
      sourceLatitude: this.tripDetails.pickupLatitude,
      sourceLongitude: this.tripDetails.pickupLongitude,
      verificationCode: null
    };
    if (AppSettings.SERVICE_CODE_RENTAL === this.tripDetails.serviceTypeCode) {
      this.rentalEndTripService(this.tripEndData);
    } else {
      this.taxiAndChauffeurEndTripService(this.tripEndData);
    }
  }

  rentalEndTripService(tripEndData) {
    this.commonService.rentalEndTrip(tripEndData, this.tripDetails.id).subscribe(results => {
      this.onStatusChange.emit();
      this.message = [];
      this.message.push({
        severity: 'success',
        summary: 'Success',
        detail: this.commonService.getLabel('label_end_trip_message')
      });

    }, (error) => {

      this.displayMessage(error, 'error');
    });
  }

  taxiAndChauffeurEndTripService(tripEndData) {
    this.commonService.endTrip(tripEndData, this.tripDetails.tripKey).subscribe(results => {
      this.onStatusChange.emit();
      this.message = [];
      this.message.push({
        severity: 'success',
        summary: 'Success',
        detail: this.commonService.getLabel('label_end_trip_message')
      });

    }, (error) => {

      this.displayMessage(error, 'error');
    });
  }

  onCancelTrip() {
    this.displayCancelTrip = true;
  }

  onOtpPopSave(event) {
    if (event.general !== undefined) {
      this.message = [];
      this.message.push({
        severity: 'success',
        summary: 'success',
        detail: event.general[0].message
      });
    }
    this.OTP = false;
  }

  doCancel() {
    const input = {
      cancellationReason: this.cancelReasonText
    };
    this.bookingService.cancelTrip(input, this.tripDetails.tripKey).subscribe(results => {
      this.onStatusChange.emit();
      this.displayMessage(results, 'success');
      this.displayCancelTrip = false;
    }, (error) => {

      this.displayMessage(error, 'success');
    });
  }

  cancel() {
    this.displayCancelTrip = false;
  }

  makeQuotationURL(event) {
  }

  onUploadQuote() {
    this.child.showDialog();
  }

  showDialog() {
    this.display = true;
  }

  displayMessage(response, severity) {
    this.message = [];
    this.message.push({
      severity: severity,
      summary: severity,
      detail: response.general[0].message
    });
  }

  reloadGrid() {
    this.closeCancelBookingView();
    this.reloadSalesQuotationTripDetails();
  }

  closeCancelBookingView() {
    this.cancelQuotationView = false;
  }

  reloadSalesQuotationTripDetails() {
    this.findId();
    this.salesQuotationBookingDetailsService.getSaleQuatationDetails(this.salesQuatationId).subscribe(results => {
      this.salesQuotationBookingDetailsService.bookingDetails = results;
      const bookingDetails = new SalesQuotationBookingDetailsModal(results);
      this.tripDetails = bookingDetails.salesQuotationDetails;
    }, (error) => {

      this.message = [];
      this.message.push({ severity: 'error', summary: 'Error Message', detail: error.general[0].message });
    });
  }

  findId() {
    this.salesQuatationId = this.route.snapshot.queryParams['id'];
  }

  cancelSalesBooking() {
    this.cancelQuotationView = true;
  }


  onStartTripProcess(event) {
    this.message = [];
    const tripStartData = {
      sourceLatitude: this.tripDetails.pickupLatitude,
      sourceLongitude: this.tripDetails.pickupLongitude,
      verificationCode: event
    };

    this.commonService.startTrip(tripStartData, this.tripDetails.tripKey).subscribe(results => {
      this.onStatusChange.emit();
      this.onOtpPopSave(results);
    }, (error) => {

      this.displayMessage(error, 'error');
    });
  }


  onSecurityCheckProcess(event) {
    this.message = [];
    const checkData = {
      type: AppSettings.SECURITY_CHECK_TYPE.security,
      verificationCode: event,
      note: null,
      startKms: null,
      endKms: null,
      documents: []
    };

    this.commonService.securityCheck(checkData, this.tripDetails.tripKey).subscribe(results => {
      this.onStatusChange.emit();
      this.message = [];
      this.message.push({
        severity: 'success',
        summary: 'success',
        detail: results.message
      });
      this.securityCheckOTP = false;

    }, (error) => {

      this.displayMessage(error, 'success');
    });
  }

  onDeliveryCheckProcess(event) {
    this.message = [];
    const checkData = {
      type: AppSettings.SECURITY_CHECK_TYPE.delivery,
      verificationCode: null,
      note: null,
      startKms: event,
      endKms: null,
      documents: []
    };

    this.commonService.securityCheck(checkData, this.tripDetails.tripKey).subscribe(results => {
      this.onStatusChange.emit();
      this.message = [];
      this.message.push({
        severity: 'success',
        summary: 'success',
        detail: results.message
      });
      this.deliveryCheckStartKM = false;

    }, (error) => {

      this.displayMessage(error, 'success');
    });
  }


  onCarReturnCheckProcess(event) {
    this.message = [];
    const checkData = {
      type: AppSettings.SECURITY_CHECK_TYPE.carReturn,
      verificationCode: null,
      note: null,
      startKms: null,
      endKms: null,
      documents: []
    };

    this.commonService.securityCheck(checkData, this.tripDetails.tripKey).subscribe(results => {
      this.onStatusChange.emit();
      this.message = [];
      this.message.push({
        severity: 'success',
        summary: 'success',
        detail: results.message
      });
      this.carReturnCheckEndTrip = false;

    }, (error) => {

      this.displayMessage(error, 'success');
    });
  }

  onReturnSecurityCheckProcess(event) {
    this.message = [];
    const checkData = {
      type: AppSettings.SECURITY_CHECK_TYPE.returnSecurity,
      verificationCode: null,
      note: null,
      startKms: null,
      endKms: event,
      documents: []
    };

    this.commonService.securityCheck(checkData, this.tripDetails.tripKey).subscribe(results => {
      this.onStatusChange.emit();
      this.message = [];
      this.message.push({
        severity: 'success',
        summary: 'Success',
        detail: results.message
      });
      this.returnSecurityCheckEndKM = false;

    }, (error) => {

      this.displayMessage(error, 'success');
    });
  }


  closeAllPopup() {
    this.OTP = false;
    this.display = false;
    this.displayCancelTrip = false;
    this.cancelQuotationView = false;
    this.securityCheckOTP = false;
    this.deliveryCheckStartKM = false;
    this.carReturnCheckEndTrip = false;
    this.returnSecurityCheckEndKM = false;
  }

}
