import { HotelBookingService } from './../../../hotel-booking/services/hotel-booking.service';
import { TaxiBookingModel } from './../../../bookings/models/taxi-booking-model';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Message } from 'primeng/components/common/api';
import { AppSettings } from './../../../shared/app.settings';
import { ErrorBindingService } from '../../../shared/services/error-binding.service';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';


@Component({
  selector: 'app-book-chauffeur',
  templateUrl: './book-chauffeur.component.html'
})
export class BookChauffeurComponent implements OnInit {
  @Input() type;

  message: Message[] = [];
  packageOptions = [];
  cmmOptions = [];
  apiError: any = [];
  minDate: any;
  automaticPackages = [{}];
  manualPackages = [];
  dateFormat;

  passengerSearchList: any[];
  passengerSearchTextResult: any[];
  passengerSelectedItem: any;
  passengerSearchText = '';

  isCorporatePassenger = false;
  selectedPassengerCorporate;
  transmission = false;
  passengerId = '';

  srcLat = '';
  srcLng = '';
  destiLat = '';
  destiLng = '';
  pickupLocation = '';
  dropLocation = '';

  labelSave;
  labelCancel;
  label_pickup;
  label_drop;
  label_search;

  serviceIds = [];
  vehicleTypeId = [];
  hotelList = [];




  urlPassengers = '/secure/passengers/search/list';


  bookingChauffeurForm = new FormGroup({
    pickupLocation: new FormControl(),
    dropLocation: new FormControl(),
    pickupDate: new FormControl(),
    package: new FormControl(),
    passengerName: new FormControl(),
    phoneCode: new FormControl(),
    mobileNo: new FormControl(),
    note: new FormControl(),
    VIPPassenger: new FormControl(),
    transmissionType: new FormControl(),
    email: new FormControl(),
    makeModel: new FormControl(),
  });

  packageApiError = [{
    driverId: false,
    serviceTypeId: false,
    partnerId: false,
    partnerType: false,
    phoneNum: false,
    passengerName: false,
    vehicleTypeId: false,
    vehicleId: false,
    packageId: false,
    bookingStatus: false,
    sourceLattitude: false,
    sourceLongitude: false,
    destinationLatitude: false,
    destinationLongitude: false,
    pickupLocationName: false,
    dropLocationName: false,
    pickupDateTime: false,
    passangerCount: false,
    vip: false,
    vehicleAutomatic: false,
    withFuel: false,
    note: false,
    general: false,
    roomNum: false,
    quotationAmount: false,
    hotelNames: false,
    makeModel: false,
  }];

  constructor(
    private formBuilder: FormBuilder,
    private errorBindingService: ErrorBindingService,
    private commonBindingDataService: CommonBindingDataService,
    private hotelBookingService: HotelBookingService
  ) { }



  assignLabels() {
    this.minDate = this.commonBindingDataService.getBookLaterMinTime(AppSettings.SETTING_CHAUFFEUR_SERVICE_KEY,
      AppSettings.CHAUFFEUR_BOOK_MIN_TIME);
    this.labelSave = this.commonBindingDataService.getLabel('label_save');
    this.label_search = this.commonBindingDataService.getLabel('label_search');
    this.labelCancel = this.commonBindingDataService.getLabel('label_cancel');
    this.label_pickup = this.commonBindingDataService.getLabel('label_pickup_location');
    this.label_drop = this.commonBindingDataService.getLabel('label_drop_location');
    this.automaticPackages.push({ label: this.commonBindingDataService.getLabel('please_select'), value: '0' });
    this.manualPackages.push({ label: this.commonBindingDataService.getLabel('please_select'), value: '0' });
  }

  validation() {
    this.bookingChauffeurForm = this.formBuilder.group({
      pickupLocation: ['', [Validators.required]],
      dropLocation: ['', [Validators.required]],
      pickupDate: ['', [Validators.required]],
      package: ['', [Validators.required]],
      passengerName: ['', [
        Validators.required,
        Validators.pattern(AppSettings.PERSON_NAME_PATTERN),
        Validators.minLength(3)
      ]],
      phoneCode: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(3),
        Validators.pattern(AppSettings.PHONE_CODE)
      ]],
      mobileNo: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(20),
      ]],
      note: [''],
      VIPPassenger: [false],
      transmissionType: ['false', Validators.required],
      makeModel: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(AppSettings.EMAIL_PATTERN)]]
    });
  }

  ngOnInit() {
    this.dateFormat = this.commonBindingDataService.getDateFormat();

    this.assignLabels();
    this.validation();

    if (this.type === 'hotel-bookings') {
      this.bookingChauffeurForm.addControl('roomNum',
        new FormControl('', [Validators.required,
        Validators.maxLength(9)])
      );
      this.bookingChauffeurForm.addControl('quotationAmount',
        new FormControl('', [Validators.required, Validators.pattern(AppSettings.AMOUNT_PATTERN)])
      );
      this.bookingChauffeurForm.addControl('hotelNames',
        new FormControl('', [Validators.required])
      );
      this.getHotels();
    }
    this.packageOptions.push({ label: this.commonBindingDataService.getLabel('please_select'), value: '0' });
  }

  getHotels() {
    this.hotelList = [{ label: this.commonBindingDataService.getLabel('please_select'), value: '0' }];
    this.commonBindingDataService.getHotels().subscribe(results => {
      results.forEach(element => {
        this.hotelList.push({ label: element.hotelName, value: element.hotelId });
      });
    }, (error) => {
      this.errorBindingService.onAPIValidationError(error, this.packageApiError);
    });
  }

  addbooking() {

    if (this.bookingChauffeurForm.controls.transmissionType.value !== 'true') {
      this.transmission = false;
    } else {
      this.transmission = true;
    }

    let mobileNum = '';
    if (this.isMobileNumberAssigned()) {
      mobileNum = this.bookingChauffeurForm.controls.mobileNo.value.phoneNum;
    } else {
      mobileNum = this.bookingChauffeurForm.controls.mobileNo.value;
    }

    const data = new TaxiBookingModel({
      paymentMode: null,
      tenantId: AppSettings.TENANT,
      bookingStatus: null,
      bookLater: true,
      passengerId: this.passengerId,
      destinationLatitude: this.destiLat,
      destinationLongitude: this.destiLng,
      dropLocationName: this.dropLocation,
      note: this.bookingChauffeurForm.controls.note.value,
      packageId: this.bookingChauffeurForm.controls.package.value,
      partnerId: null,
      partnerType: '',
      corporateBooking: this.isCorporatePassenger,
      passengerName: this.bookingChauffeurForm.controls.passengerName.value,
      numCountryCode: this.bookingChauffeurForm.controls.phoneCode.value,
      phoneNum: mobileNum,
      pickupDateTime: new Date(this.bookingChauffeurForm.controls.pickupDate.value).getTime(),
      pickupLocationName: this.pickupLocation,
      serviceTypeId: AppSettings.SETTING_CHAUFFEUR_SERVICE,
      sourceLatitude: this.srcLat,
      sourceLongitude: this.srcLng,
      vehicleAutomatic: this.transmission,
      vehicleId: null,
      vehicleTypeId: null,
      vip: this.bookingChauffeurForm.controls.VIPPassenger.value,
      withFuel: false,
      bookingDropPoints: [],
      email: this.bookingChauffeurForm.controls.email.value,
      quoteAmount: this.bookingChauffeurForm.controls.quotationAmount.value,
      roomNum: this.bookingChauffeurForm.controls.roomNum.value,
      passengerVehicleModel: this.bookingChauffeurForm.controls.roomNum.value,
    });

    const hotelId = this.bookingChauffeurForm.controls.hotelNames.value;
    if (this.minDate > new Date(this.bookingChauffeurForm.controls.pickupDate.value).getTime()) {
      const error = {
        general: [
          {
            message: this.commonBindingDataService.getLabel('error_invalid_date')
          }
        ]
      };
      this.errorBindingService.onAPIValidationError(JSON.stringify(error), this.packageApiError);
    } else {
      this.commonBindingDataService.addHotelBooking(JSON.stringify(data),
        AppSettings.SETTING_CHAUFFEUR_SERVICE, hotelId).subscribe(results => {
          this.successSave(results);
        }, (error) => {
          this.errorBindingService.onAPIValidationError(error, this.packageApiError);
        });
    }
  }

  successSave(results) {
    this.message = [];
    this.message.push({ severity: 'success', summary: 'Confirmed', detail: results.general[0].message });
    this.bookingChauffeurForm.reset();
    this.hotelBookingService.gridBind();
  }

  cancel() {
    this.bookingChauffeurForm.reset();
  }

  setPickup(place) {
    this.srcLat = place.lat;
    this.srcLng = place.lng;
    this.pickupLocation = place.address;
  }

  setDropUp(place) {
    this.destiLat = place.lat;
    this.destiLng = place.lng;
    this.dropLocation = place.address;
  }


  passengerSearch(event) {
    const searchText = event.query;
    this.commonBindingDataService.searchPassengers(this.getPassengersSearchUrl(searchText))
      .subscribe(data => {
        if (data !== null) {
          this.passengerSearchList = data;
          const c = this;
          this.passengerSearchTextResult = [];
          this.passengerSearchList.forEach(e => {
            e.phoneNum = parseInt(e.phoneNum, 10);
            c.passengerSearchTextResult.push({
              phoneNum: parseInt(e.phoneNum, 10),
              email: e.email,
              numCountryCode: e.numCountryCode,
              name: e.firstName + ' ' + this.checkNull(e.lastName),
              corporateId: e.corporateId,
              corporateName: e.corporateName,
              id: e.id
            });
          });
        }
      });
  }

  getPassengersSearchUrl(searchText) {
    let url;
    if (this.urlPassengers !== null && this.urlPassengers !== '') {
      url = `${this.urlPassengers}?searchText=${searchText}`;
    }
    return url;
  }


  checkNull(obj) {
    if (obj !== null) {
      return obj;
    } else {
      return '';
    }
  }


  onSelectMobileNumber(event) {
    this.bookingChauffeurForm.controls.passengerName.setValue(event.name);
    this.bookingChauffeurForm.controls.email.setValue(event.email);
    this.bookingChauffeurForm.controls.phoneCode.setValue(event.numCountryCode);
    this.selectedPassengerCorporate = event.corporateName;
    if (event.corporateId !== null) {
      this.isCorporatePassenger = true;
      this.passengerId = event.id;
    } else {
      this.isCorporatePassenger = false;
      this.passengerId = '';
    }
    this.allPackage();
  }

  allPackage() {
    this.packageOptions = [];
    this.packageOptions.push();
    this.commonBindingDataService.packageBind(
      this.commonBindingDataService.getServiceTypeId(AppSettings.SETTING_CHAUFFEUR_SERVICE_KEY)
    ).subscribe(results => {
      this.sortPackages(results);
    }, (error) => {
      this.errorBindingService.onAPIValidationError(error, this.packageApiError);
    });
    this.serviceIds.push(this.commonBindingDataService.getServiceTypeId(AppSettings.SETTING_CHAUFFEUR_SERVICE_KEY));
  }

  sortPackages(packages) {
    this.automaticPackages = [];
    this.manualPackages = [];
    this.automaticPackages.push({ label: this.commonBindingDataService.getLabel('please_select'), value: '0' });
    this.manualPackages.push({ label: this.commonBindingDataService.getLabel('please_select'), value: '0' });
    for (const pack of packages) {
      if (pack.transmissionType.includes(AppSettings.AUTOMATIC)) {
        this.automaticPackages.push({ label: pack.packageName, value: pack.id });
      } else if (pack.transmissionType.includes(AppSettings.MANUAL)) {
        this.manualPackages.push({ label: pack.packageName, value: pack.id });
      } else {
        this.automaticPackages.push({ label: pack.packageName, value: pack.id });
        this.manualPackages.push({ label: pack.packageName, value: pack.id });
      }
    }

    console.log(this.automaticPackages);
    console.log(this.manualPackages);
  }

  onTransmissionChange() {
    if (this.bookingChauffeurForm.controls.transmissionType.value !== 'true') {
      this.transmission = false;
    } else {
      this.transmission = true;
    }
  }

  isMobileNumberAssigned() {
    if (typeof (this.bookingChauffeurForm.controls.mobileNo.value) === 'object') {
      return true;
    } else {
      return false;
    }
  }
}


