import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, } from 'primeng/components/common/api';
import { AppSettings } from './../../app.settings';
import { StorageService } from './../../services/storage.service';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';

@Component({
  selector: 'app-navtab',
  templateUrl: './navtab.component.html'
})
export class NavtabComponent implements OnInit {
  @Input() type;
  public settingsNavs: MenuItem[];
  activeItem: MenuItem;
  serviceType: any;
  constructor(private router: Router,
    private commonBindingDataService: CommonBindingDataService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.serviceType = this.storageService.getItem(AppSettings.SERVICE_TYPE);
    const c = this;
    this.settingsNavs = [];
    this.serviceType.forEach(element => {
      switch (element.serviceType) {
        case 'Taxi':
          AppSettings.SETTING_TAXI_SERVICE = element.id;
          this.settingsNavs.push({
            label: this.commonBindingDataService.getLabel('label_taxi'),
            command: (event) => {
              this.router.navigate([this.type + '/taxi']);
            }
          });
          break;
        case 'Rental':
          AppSettings.SETTING_RENTAL_WITH_SERVICE = element.id;
          this.settingsNavs.push({
            label: this.commonBindingDataService.getLabel('label_rental_car'),
            command: (event) => {
              this.router.navigate([this.type + '/rental-car']);
            }
          });
          break;
        case 'Chauffeur':
          AppSettings.SETTING_CHAUFFEUR_SERVICE = element.id;
          this.settingsNavs.push({
            label: this.commonBindingDataService.getLabel('label_chauffeur'),
            command: (event) => {
              this.router.navigate([this.type + '/chauffeur']);
            }
          });
          break;
      }
    });
    if (this.settingsNavs.length === 1) {
      this.settingsNavs = [];
    }

    const currentInc = this;
    setTimeout(function() {
      currentInc.showCurrentMenu();
    }, 200);


  }

  showCurrentMenu() {
    switch (this.router.url) {
      case '/bookings/add-taxi': {
        this.activeItem = this.settingsNavs[0];
        break;
      }
      case '/bookings/rental-car': {
        this.activeItem = this.settingsNavs[1];
        break;
      }
      case '/bookings/chauffeur': {
        this.activeItem = this.settingsNavs[2];
        break;
      }
      default:
        this.activeItem = this.settingsNavs[0];
        break;
    }
  }

}
