import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { AuthenticationValidationService } from './modules/shared/services/authentication-validation.service';
import { TrackMyLocationComponent } from './pages/track-my-location/track-my-location.component';
import { PaymentStripComponent } from './modules/payment-strip/payment-strip.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { AuthorizeGatewayComponent } from './pages/authorize-gateway/authorize-gateway.component';
import { AuthorizeSuccessPageComponent } from './pages/authorize-success-page/authorize-success-page.component';
import { AuthorizedFailurePageComponent } from './pages/authorized-failure-page/authorized-failure-page.component';
import { AuthorizeCardDetailsComponent } from './pages/authorize-card-details/authorize-card-details.component';
import { AuthorizedPendingPaymentComponent } from './pages/authorized-pending-payment/authorized-pending-payment.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'admin-dashboard',
    canActivate: [AuthenticationValidationService]

  },
  {
    path: 'signin',
    pathMatch: 'full',
    component: SignInComponent
  },
  {
    path: 'trackmylocation',
    pathMatch: 'full',
    component: TrackMyLocationComponent
  },
  {
    path: 'privacy-policy',
    pathMatch: 'full',
    component: PrivacyPolicyComponent
  },
  {
    path: 'authorize-dot-net/add-update-card',
    pathMatch: 'full',
    component: AuthorizeGatewayComponent
  },
  {
    path: 'authorize-dot-net/wallet-topup',
    pathMatch: 'full',
    component: AuthorizeCardDetailsComponent
  },
  {
    path: 'authorize-dot-net/pending-payment',
    pathMatch: 'full',
    component: AuthorizedPendingPaymentComponent
  },
  {
    path: 'authorize-dot-net/success-page',
    pathMatch: 'full',
    component: AuthorizeSuccessPageComponent
  },
  {
    path: 'authorize-dot-net/failure-page',
    pathMatch: 'full',
    component: AuthorizedFailurePageComponent
  },
  {
    path: 'admin-dashboard',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/admin-dashboard/admin-dashboard.module#AdminDashboardModule'
  },
  {
    path: 'bookings',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/bookings/bookings.module#BookingsModule'
  },
  {
    path: 'passengers',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/passengers/passengers.module#PassengersModule'
  },
  {
    path: 'manage-corporates',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/corporate-passenger/corporate-passenger.module#CorporatePassengerModule'
  },
  {
    path: 'occasional-trip',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/occasional-trip/occasional-trip.module#OccasionalTripModule'
  },
  {
    path: 'vehicles',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/vehicles/vehicles.module#VehiclesModule'
  },
  {
    path: 'drivers',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/drivers/drivers.module#DriversModule'
  },
  {
    path: 'manage-cities',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/manage-cities/manage-cities.module#ManageCitiesModule'
  },
  {
    path: 'manage-companies',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/manage-companies/manage-companies.module#ManageCompaniesModule'
  },
  {
    path: 'marketing',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/marketing/marketing.module#MarketingModule'
  },
  {
    path: 'app-settings',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/tenant-settings/tenant-settings.module#TenantSettingsModule'
  },
  {
    path: 'user-management',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/user-management/user-management.module#UserManagementModule'
  },
  {
    path: 'operator-management',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/operator-management/operator-management.module#OperatorManagementModule'
  },
  {
    path: 'settings',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/settings/settings.module#SettingsModule'
  },
  {
    path: 'reports',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/reports/reports.module#ReportsModule'
  },
  {
    path: 'payment',
    pathMatch: 'full',
    component: PaymentStripComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
