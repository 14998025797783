import { CommonBindingDataService } from './../../services/common-binding-data.service';
import { UiService } from './../../services/ui.service';
import { UserCommonService } from './../../services/user-common.service';
import { StorageService } from './../../services/storage.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Message } from 'primeng/components/common/api';
import { AppSettings } from '../../app.settings';

@Component({
  selector: 'app-otp-verifiy',
  templateUrl: './otp-verifiy.component.html'
})
export class OtpVerifiyComponent implements OnInit {
  @Input() email;
  @Input() phone;

  @Output() onOtpPopClose: EventEmitter<any> = new EventEmitter();
  @Output() onOtpPopSave: EventEmitter<any> = new EventEmitter();

  msg: Message[] = [];
  param = 'userType=admin';
  otpForm = new FormGroup({
    otpInput: new FormControl()
  });

  resendOTPDisable = true;

  constructor(
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private commonServices: UserCommonService,
    private uiSerice: UiService,
    private commonBindingDataService: CommonBindingDataService,
  ) { }


  ngOnInit() {
    this.otpForm = this.formBuilder.group({
      otpInput: ['', [Validators.required, Validators.minLength(4)]],
    });
  }

  closeEditForgot(event) {
    this.otpForm.reset();
    this.onOtpPopClose.emit();
  }

  resendPassword(event) {
    this.resendOTPDisable = true;
    let input;
    let otpEmail;
    let otpPhone;
    const userName = this.email;
    if (userName.match(AppSettings.EMAIL_PATTERN)) {
      otpEmail = userName;
      input = {
        email: userName,
        tenantId: AppSettings.TENANT
      };
    } else if (userName.match(AppSettings.PHONE_PATTERN)) {
      otpPhone = userName;
      input = {
        phoneNum: userName,
        tenantId: AppSettings.TENANT
      };
    } else {
      input = {
        username: userName,
        tenantId: AppSettings.TENANT
      };
    }
    this.commonServices.resendOtp(this.param, JSON.stringify(input)).subscribe(results => {
      this.dispalyMessage(results.general[0].message, 'success');
    }, (error) => {
      this.dispalyMessage(error.general[0].message, 'error');
    });
  }

  changePassword(event) {
    this.msg = [];
    let input;
    this.resendOTPDisable = true;
    const otpInput = this.otpForm.controls.otpInput.value;
    input = {
      otp: otpInput,
      email: this.email
    };

    this.commonServices.validateOtp(JSON.stringify(input)).subscribe(results => {
      this.onOtpPopSave.emit(results);
      this.otpForm.reset();
    }, (error) => {

      this.dispalyMessage(error.general[0].message, 'error');
      this.resendOTPDisable = false;
    });
  }

  dispalyMessage(message, msgType) {
    this.msg = [];
    this.msg.push({
      severity: msgType,
      summary: msgType,
      detail: message
    });
  }

}
