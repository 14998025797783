import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './modules/shared/shared.module';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { InputTextModule } from 'primeng/components/inputtext/inputtext';
import { StorageService } from './modules/shared/services/storage.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderService } from './modules/shared/components/loader/loader.service';
import { EditorModule } from 'primeng/components/editor/editor';
import { PanelModule } from 'primeng/components/panel/panel';
import { GrowlModule } from 'primeng/components/growl/growl';
import { MessageModule } from 'primeng/components/message/message';
import { DialogModule } from 'primeng/components/dialog/dialog';
import { ButtonModule } from 'primeng/components/button/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/components/common/api';
import { CalendarModule } from 'primeng/components/calendar/calendar';
import { FieldsetModule } from 'primeng/components/fieldset/fieldset';
import { FileUploadModule } from 'primeng/components/fileupload/fileupload';
import { MultiSelectModule } from 'primeng/components/multiselect/multiselect';
import { OverlayPanelModule } from 'primeng/components/overlaypanel/overlaypanel';
import { ConfirmDialogModule } from 'primeng/components/confirmdialog/confirmdialog';
import { NotificationsService } from './modules/shared/services/notifications.service';
import { TrackMyLocationComponent } from './pages/track-my-location/track-my-location.component';
import { LoginComponent } from './pages/login/login.component';
import { PasswordModule } from 'primeng/components/password/password';
import { MessageService } from 'primeng/components/common/messageservice';
import { ToastModule } from 'primeng/toast';
import { PaymentStripComponent } from './modules/payment-strip/payment-strip.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { MessagesModule } from 'primeng/components/messages/messages';
import { AuthorizeGatewayComponent } from './pages/authorize-gateway/authorize-gateway.component';
import { AuthorizeSuccessPageComponent } from './pages/authorize-success-page/authorize-success-page.component';
import { AuthorizedFailurePageComponent } from './pages/authorized-failure-page/authorized-failure-page.component';
import { AuthorizeCardDetailsComponent } from './pages/authorize-card-details/authorize-card-details.component';
import { AuthorizedPendingPaymentComponent } from './pages/authorized-pending-payment/authorized-pending-payment.component';


@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    TrackMyLocationComponent,
    LoginComponent,
    PaymentStripComponent,
    PrivacyPolicyComponent,
    AuthorizeGatewayComponent,
    AuthorizeSuccessPageComponent,
    AuthorizedFailurePageComponent,
    AuthorizeCardDetailsComponent,
    AuthorizedPendingPaymentComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PanelModule,
    InputTextModule,
    EditorModule,
    DialogModule,
    ButtonModule,
    GrowlModule,
    MessagesModule,
    MessageModule,
    MultiSelectModule,
    AppRoutingModule,
    FieldsetModule,
    OverlayPanelModule,
    CalendarModule,
    FileUploadModule,
    ConfirmDialogModule,
    PasswordModule,
    SharedModule.forRoot(),
    MessagesModule
  ],
  providers: [StorageService, ConfirmationService, LoaderService, NotificationsService, MessageService, ToastModule],
  bootstrap: [AppComponent]
})
export class AppModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AppModule
    };
  }
  constructor() {
  }
}
