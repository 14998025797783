import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserCommonService } from '../../modules/shared/services/user-common.service';
import { ActivatedRoute } from '@angular/router';
import { Message } from 'primeng/components/common/api';
import { AppSettings } from '../../modules/shared/app.settings';

declare const google: any;

@Component({
  selector: 'app-track-my-location',
  templateUrl: './track-my-location.component.html'
})

export class TrackMyLocationComponent implements OnInit, OnDestroy {
  map: any;
  marker;
  markers = [];
  directionsService;
  directionsDisplay = new google.maps.DirectionsRenderer;
  maxRating = AppSettings.MAX_RATING;
  locationDetails: any = null;
  bookingId;
  source;
  destination;
  current;
  currentLat;
  currentLong;
  emergecyrequestedAt;
  sos;
  liveLocationInterval;
  message: Message[] = [];
  tourStatus;
  tourStatusColor: any = null;
  tourStatusCode = 0;
  END_TRIP = AppSettings.BOOKING_STATUS.END_TRIP;
  isMapPointChanged = false;
  isFromSource = true;
  tourBookingInfo:any;
  profileImageUrl: string = AppSettings.GET_FILE_THUMB_URL;
  imageUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
  showTrackMyLocationPage = false;
  errorMessage = '';
  currentStatus: Number;
  constructor(
    private userCommonService: UserCommonService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.bookingId = this.route.snapshot.queryParams['id'];
    this.getLiveData();
    this.getTrackMyLocationDetails();
  }

  getTrackMyLocationDetails() {

    this.userCommonService.getTrackMyLocationDetails(this.bookingId).subscribe((result) => {
   
      this.showTrackMyLocationPage = true;
      this.locationDetails = result;
      this.tourBookingInfo = result.tourBookingDetailsDto.tourBookingInfo;
      if(result.tourBookingDetailsDto.tourBookingInfo){
        this.tourStatus = result.tourBookingDetailsDto.tourBookingInfo.tourStatusString;
        this.setStatusColor(this.tourStatus);
        this.tourStatusCode = result.tourBookingDetailsDto.tourBookingInfo.tourStatus;
      }
      if (result.tourBookingDetailsDto.driverInfo.profileImage !== '' && result.tourBookingDetailsDto.driverInfo.profileImage !== null) {
        this.imageUrl = this.profileImageUrl + result.tourBookingDetailsDto.driverInfo.profileImage;
      }
      this.calculateAndDisplayRoute();
      this.map = new google.maps.Map(document.getElementById('trackLocationMap'), {
        center: this.destination,
        zoom: 6,
        gestureHandling: 'cooperative',
        zoomControl: true
      });
      if(result.emergencyToken) {
        this.emergecyrequestedAt = result.emergencyToken.address;
        this.sos = new google.maps.LatLng(result.emergencyToken.latitude, result.emergencyToken.longitude);
       }
      this.setMarker(this.map, this.sos, 'SOS');    
      if(result.emergencyToken) {  
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(this.sos);
        this.map.fitBounds(bounds);       // auto-zoom   
   }  
      this.trackLiveLocation();
    }, (reason) => {
      this.errorMessage = 'The page you are looking is not exist. Please contact to your Admin.';
    });
  }

  setStatusColor(tourStatus) {
    switch (tourStatus) {
      case 'Completed':
        this.tourStatusColor = { 'color': 'green' };
        break;
      default:
        break;
    }
  }

  trackLiveLocation() {
    const trackLocationModel = this;
    if (this.tourStatusCode !== this.END_TRIP) {
      this.liveLocationInterval = setInterval(function() {
        trackLocationModel.getLiveData();
      }, 1000 * 15);
    }
  }

  ngOnDestroy() {
    clearInterval(this.liveLocationInterval);
  }

  getLiveData() {
    this.userCommonService.getTrackMyLiveLocation(this.bookingId).subscribe((result) => {
      this.source = new google.maps.LatLng(result.sourceLatitude, result.sourceLongitude);
      this.destination = new google.maps.LatLng(result.destinationLatitude, result.destinationLongitude);
      this.current = new google.maps.LatLng(result.driverLocationLatitude, result.driverLocationLongitude);
      this.tourStatus = result.tourStatusString;
      this.currentStatus = result.tourStatus;
      this.currentLat = result.driverLocationLatitude;
      this.currentLong = result.driverLocationLongitude
      this.isMapPointChanged = (this.isFromSource !== result.trackFromsourse) ? true : false;
      this.isFromSource = result.trackFromsourse;
      this.updateLocation();

    }, (reason) => {
      console.log(reason);
    });
  }

  updateLocation() {
    this.clearMarker();
    if (this.currentStatus !== AppSettings.BOOKING_STATUS.END_TRIP) {
      if(this.currentLat !== 0 && this.currentLong !==0){
        this.setMarker(this.map, this.current, 'driver');
        if(!this.tourBookingInfo) {  
            const bounds = new google.maps.LatLngBounds();
            bounds.extend(this.current);
            bounds.extend(this.sos);
            this.map.fitBounds(bounds);       // auto-zoom 
       }
      }
      this.setMarker(this.map, this.sos, 'SOS');
     
    } else {
      this.calculateAndDisplayRoute();
      clearInterval(this.liveLocationInterval);

    }

    if (!this.isFromSource && this.tourBookingInfo) {
      this.setMarker(this.map, this.destination, 'destination');
    }
    if(this.tourBookingInfo){

      this.setMarker(this.map, this.source, 'source');
    }

    if (this.isMapPointChanged) {
      this.calculateAndDisplayRoute();
    }
  }

  calculateAndDisplayRoute() {
    if (this.directionsDisplay !== null) {
      this.directionsDisplay.setMap(null);
      this.directionsDisplay = null;
    }

    this.directionsDisplay = new google.maps.DirectionsRenderer({
      polylineOptions: {
        strokeColor: AppSettings.DEFAULT_ROUTE_COLOR
      },
      suppressMarkers: true
    });


    this.directionsDisplay.setMap(this.map);

    this.directionsService = new google.maps.DirectionsService;

    this.directionsService.route({
      origin: this.currentStatus !== AppSettings.BOOKING_STATUS.END_TRIP ? this.current : this.source,
      destination: this.isFromSource ? this.source : this.destination,
      travelMode: 'DRIVING',
      provideRouteAlternatives: false
    }, (response, status) => {
      if (status === 'OK') {
        this.clearMarker();
        this.directionsDisplay.setDirections(response);
        this.directionsDisplay.setMap(this.map);
        // if (this.currentStatus !== AppSettings.BOOKING_STATUS.END_TRIP) {
          this.setMarker(this.map, this.current, 'driver');
          this.setMarker(this.map, this.sos, 'SOS');

        // }
        if (!this.isFromSource) {
          this.setMarker(this.map, this.destination, 'destination');
        }
        this.setMarker(this.map, this.source, 'source');
      } else { 
        this.mapMessage(status);
      }
    });
  }

  setMarker(map, location, type) {
    let icon;
    switch (type) {
      case 'source':
        icon = '/assets/images/temp/Source.png';
        break;
      case 'destination':
        icon = '/assets/images/temp/Destination.png';
        break;
      case 'SOS':
        icon = '/assets/images/temp/SOS icon.png';
          break;
      default:
        icon = '/assets/images/temp/taxi_free.png';
        break;
    }

    this.marker = new google.maps.Marker;
    this.marker.setMap(map);
    this.marker.setIcon(icon);
    this.marker.setPosition(location);
    this.markers.push(this.marker);
  }

  clearMarker() {
    for (let marker = 0; marker < this.markers.length; marker++) {
      this.markers[marker].setMap(null);
    }
  }

  mapMessage(status) {
    this.message = [];
    this.message.push({ severity: 'success', summary: 'Confirmed', detail: status });
  }

}
