import { Injectable } from '@angular/core';

import { RestApiService } from '../services/rest-api.service';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { AppSettings } from '../app.settings';
// import 'rxjs/add/operator/map';
declare var Stripe: any;
@Injectable()
export class UserCommonService {
  isMetadataLoaded = false;
  isCompanyRole = false;
  stripElements: any;
  stripe: any;
  constructor(private restApiService: RestApiService,
    private storageService: StorageService) {
    if (this.storageService.getItem(AppSettings.TOKEN_KEY)) {
      this.getStripeKeyData();
    }
    this.getAdminUserMetaData();
  }
  signOut(): Observable<any> {
    return this.restApiService.delete('/secure/signout', 'page-center');
  }

  signIn(data): Observable<any> {
    return this.restApiService.post('/user-signin', data, 'page-center');
  }

  passwordChange(data): Observable<any> {
    return this.restApiService.put('/secure/users/profile/password', data, 'page-center');
  }

  setPassword(data): Observable<any> {
    return this.restApiService.post('/users/set-password', data, 'page-center');
  }

  getUserProfile(): Observable<any> {
    return this.restApiService.get('/secure/users/profile', 'page-center');
  }

  updateUserProfile(data): Observable<any> {
    return this.restApiService.put('/secure/users/profile', data, 'page-center');
  }

  forgotPassword(data): Observable<any> {
    return this.restApiService.post('/users/forgot-password', data, 'page-center');
  }

  resendOtp(param, data): Observable<any> {
    return this.restApiService.put('/users/resend-otp?' + param, data, 'page-center');
  }

  requestOtp(data): Observable<any> {
    return this.restApiService.post('/users/signup-otp/request', data, 'page-center');
  }

  validateOtp(data): Observable<any> {
    return this.restApiService.post('/users/verify-otp', data, 'page-center');
  }

  getServiceAreaId(): Observable<any> {
    return this.restApiService.get('/secure/tenants/servicearea', 'page-center');
  }

  getUserMetaData(): Observable<any> {
    return this.restApiService.get('/secure/user-metadata', 'page-center');
  }

  getUserMetaDataInsecure(): Observable<any> {
    return this.restApiService.get('/admin/metadata', 'page-center');
  }

  getTrackMyLocationDetails(bookingId): Observable<any> {
    return this.restApiService.get('/track-my-location/' + bookingId, 'page-center');
  }

  getTrackMyLiveLocation(bookingId): Observable<any> {
    return this.restApiService.get('/track-my-location/live/' + bookingId);
  }

  getAdminUserMetaData() {
    this.getUserMetaDataInsecure().subscribe(result => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${result.googleBrowserAPIKey}&libraries=visualization,places`;
      document.getElementsByTagName('head')[0].appendChild(script);
      setTimeout(() => {
        this.isMetadataLoaded = true;
      }, 500);
    }, (error) => {
    });
  }
  getStripeKeyData() {
    this.getUserMetaData().subscribe(result => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://js.stripe.com/v3/`;
      this.stripe = Stripe(`${result.pkKey}`); // use your test publishable key
      this.stripElements = this.stripe.elements();
      document.getElementsByTagName('head')[0].appendChild(script);

    }, (error) => {
    });
  }

}

