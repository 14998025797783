import { Message } from 'primeng/components/common/api';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SalesQuotationBookingDetailsService } from './../../../sales-quotation/services/sales-quotation-booking-details.service';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';

@Component({
  selector: 'app-cancel-sale-quotation',
  templateUrl: './cancel-sale-quotation.component.html',
  providers: [SalesQuotationBookingDetailsService]
})
export class CancelSaleQuotationComponent implements OnInit {
  @Output() reloadGrid: EventEmitter<any> = new EventEmitter();
  @Output() closeCancelBookingView: EventEmitter<any> = new EventEmitter();
  @Input() quotationId;
  @Input() cancelQuotationView;
  quotationNote = '';
  cancelReason: any;
  message: Message[] = [];
  labelCancelBooking;
  labelCancel;
  constructor(private salesQuotationService: SalesQuotationBookingDetailsService,
    private commonService: CommonBindingDataService) {
  }


  ngOnInit() {
    this.labelCancelBooking = this.commonService.getLabel('label_cancel_booking');
    this.labelCancel = this.commonService.getLabel('label_cancel');
  }

  onSave() {
    this.cancelReason = {
      rejectionReason: this.quotationNote
    };

    this.salesQuotationService.cancelQuotation(this.quotationId, this.cancelReason).subscribe(results => {
      this.message = [];
      this.message.push({ severity: 'success', summary: 'Success', detail: results.general[0].message });
      setTimeout(() => {
        this.cancelQuotationView = false;
        this.reloadGrid.emit();
      }, 500);
    }, (error) => {

      this.message = [];
      this.message.push({ severity: 'error', summary: 'Error Message', detail: error.general[0].message });
    });

  }

  onCancel() {
    this.closeCancelBookingView.emit();
  }
}
