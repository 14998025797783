import { Injectable, NgZone, EventEmitter } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AppSettings } from '../app.settings';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { LoaderService } from './../components/loader/loader.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class RestApiService {

  constructor(private http: HttpClient, private zone: NgZone, private router: Router,
    private storageService: StorageService, private loaderService: LoaderService) {
  }

  private prependApiUrl(url: string): string {
    return AppSettings.BASE_URL + '/' + AppSettings.TENANT + url;
  }

  get(url: string, loader?: string): Observable<{}> {
    this.showLoader(loader);
    return this.http.get(this.prependApiUrl(url), { headers: this.getHeaders() }).pipe(
      catchError((error) => this.handleError(error))
    ).pipe(
      map((r: Response) => {
        this.hideLoader();
        return r;
      })
    );
  }

  post(url: string, body: any, loader?: string, options = { headers: this.getHeaders() }): Observable<{}> {
    this.showLoader(loader);
    return this.http.post(this.prependApiUrl(url), body, options).pipe(
      catchError((error) => this.handleError(error))
    ).pipe(
      map((r: Response) => {
        this.hideLoader();
        return r;
      })
    );
  }

  postWithCustomHeader(url: string, authorizationKey: string, body: any, loader?: string): Observable<{}> {
      
    let options = { headers: this.getCustomHeadersWithAuthorizationKey(authorizationKey)};

    this.showLoader(loader);
    return this.http.post(this.prependApiUrl(url), body, options).pipe(
      catchError((error) => this.handleError1(error))
    ).pipe(
      map((r: Response) => {
        this.hideLoader();
        return r;
      })
    );
  }

  getWithCustomHeader(url: string, authorizationKey: string, body: any, loader?: string): Observable<{}> {
      
    let options = { headers: this.getCustomHeadersWithAuthorizationKey(authorizationKey)};

    this.showLoader(loader);
    return this.http.get(this.prependApiUrl(url), options).pipe(
      catchError((error) => this.handleError1(error))
    ).pipe(
      map((r: Response) => {
        this.hideLoader();
        return r;
      })
    );
  }


  put(url: string, body?: any, loader?: string, options = { headers: this.getHeaders() }): Observable<{}> {
    this.showLoader(loader);
    return this.http.put(this.prependApiUrl(url), body, options).pipe(
      catchError((error) => this.handleError(error))
    ).pipe(
      map((r: Response) => {
        this.hideLoader();
        return r;
      })
    );
  }

  delete(url: string, loader?: string, options = { headers: this.getHeaders() }): Observable<{}> {
    this.showLoader(loader);
    return this.http.delete(this.prependApiUrl(url), options).pipe(
      catchError((error) => this.handleError(error))
    ).pipe(
      map((r: Response) => {
        this.hideLoader();
        return r;
      })
    );
  }

  patch(url: string, body: any, loader?: string, options = { headers: this.getHeaders() }): Observable<{}> {
    this.showLoader(loader);
    return this.http.patch(this.prependApiUrl(url), body, options);
  }

  head(url: string, loader?: string, options = { headers: this.getHeaders() }): Observable<{}> {
    this.showLoader(loader);
    return this.http.head(this.prependApiUrl(url), options);
  }

  options(url: string, loader?: string, options = { headers: this.getHeaders() }): Observable<{}> {
    this.showLoader(loader);
    return this.http.options(this.prependApiUrl(url), options);
  }

  excel(url: string, fileName: string, loader?: string) {
    this.showLoader(loader);
    return this.http.get(this.prependApiUrl(url), { headers: this.getHeaders(), responseType: 'blob' }).subscribe(data =>
      this.downloadFile(data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', fileName)),
      error => this.handleError(error);
  }

  pdf(url: string, fileName: string, loader?: string,
    options = {
      headers: this.getHeaders(),
      responseType: 'blob'
    }) {
    this.showLoader(loader);
    return this.http.get(this.prependApiUrl(url), { headers: this.getHeaders(), responseType: 'blob' }).subscribe(data => {
      this.downloadFile(data, 'application/pdf', fileName);
    }),
      error => this.handleError(error);
  }

  //   getFileNameFromHttpResponse(httpResponse) {
  //     const contentDispositionHeader = httpResponse.headers.get('Content-Disposition');
  //     const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
  //     return result.replace(/'/g, '');
  // }

  image(url: string, fileName: string, loader?: string) {
    this.showLoader(loader);
    return this.http.get(url, { headers: this.getHeaders(), responseType: 'blob' }).subscribe(data =>
      this.downloadFile(data, this.getContentType(fileName), fileName)),
      error => this.handleError(error);
  }

  private getHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
      'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
      'Accept': AppSettings.HEADER_CONTENT_TYPE
    });
    const todayDate = new Date().toString();
    AppSettings.HEADER_TIMEZONE_VALUE = todayDate.substr(todayDate.search(AppSettings.TIME_ZONE_FIRST_STRING),
      todayDate.search(AppSettings.TIME_ZONE_SECOND_STRING)).replace('(', '').replace(')', '');
    if (AppSettings.HEADER_AUTHORIZATION_VALUE !== null) {
      headers = new HttpHeaders({
        'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
        'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
        'Accept': AppSettings.HEADER_CONTENT_TYPE,
        'Authorization': 'Bearer ' + this.storageService.getItem(AppSettings.TOKEN_KEY),
        'Timezone': AppSettings.HEADER_TIMEZONE_VALUE
      });
    }
    return headers;
  }

  private getCustomHeadersWithAuthorizationKey(authorizationKey: string): HttpHeaders {
    let headers = new HttpHeaders({
      'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
      'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
      'Accept': AppSettings.HEADER_CONTENT_TYPE,
      'Authorization': 'Bearer ' + authorizationKey,
      'Timezone': AppSettings.HEADER_TIMEZONE_VALUE
    });
    return headers;
  }

  private handleError(error: HttpErrorResponse | any) {
    this.hideLoader();
    if (error.status === 400) {
      return throwError(error.error);
    } else if (error.status === 500 || error.status === 403) {
      return throwError(error);
    } else if (error.status === 401) {
      this.router.navigate(['/signin']);
    }
    // TODO handle 401 and other errors;
  }

  private handleError1(error: HttpErrorResponse | any) {
    this.hideLoader();
    if (error.status === 400) {
      return throwError(error.error);
    } else if (error.status === 500 || error.status === 403) {
      return throwError(error);
    } else if (error.status === 401) {
      this.router.navigate(['/authorize-failure-page']);
    }
    // TODO handle 401 and other errors;
  }

 
  downloadFile(data: any, contentType: string, fileName: string) {
    const blob = new Blob([data], { type: contentType });
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    this.hideLoader();
    setTimeout(() => {
      link.remove()
    }, 1000);
  }

  private getContentType(fileName: string) {
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
    switch (extension) {
      case 'jpeg':
        return 'image/jpeg';
      case 'jpg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'gif':
        return 'image/gif';
      case 'bmp':
        return 'image/x-ms-bmp';
      case 'pdf':
        return 'application/pdf';
      case 'xls':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    return '';
  }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(loader?: string): void {
    if (loader !== undefined && loader !== null && 'none' !== loader.toLowerCase()) {
      this.loaderService.show(loader);
    }
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }
}
