import { Component, OnInit, Input, NgZone, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Message } from 'primeng/components/common/api';
import { ErrorBindingService } from './../../services/error-binding.service';
import { CommonBindingDataService } from './../../services/common-binding-data.service';
import { UserCommonService } from './../../services/user-common.service';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {
  errorMessage: Message[] = [];
  @Input() changePasswordPopup: boolean;
  @Output() isShowChangePassword: EventEmitter<any> = new EventEmitter();
  passwordType;
  changePasswordErrors = [
    {
      oldPassword: false,
      newPassword: false,
      newConfirmPassword: false,
    }
  ];

  chagePasswordForm = new FormGroup({
    oldPassword: new FormControl(),
    newPassword: new FormControl(),
    newConfirmPassword: new FormControl()
  });

  formMessageTextColor = '';
  validForm = false;

  constructor(
    private formBuilder: FormBuilder,
    private signOutService: UserCommonService,
    private commonService: CommonBindingDataService,
    private errorBindingService: ErrorBindingService
  ) { }

  ngOnInit() {
    this.chagePasswordForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(25)]],
      newPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(25)]],
      newConfirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(25)]]
    });
    const error = {
      general: [
        {
          message: ''
        }
      ]
    };
    this.errorBindingService.onAPIValidationError(error, this.changePasswordErrors);
  }

  passwordNotMatched() {
    if (this.chagePasswordForm.controls.newPassword.value !==
      this.chagePasswordForm.controls.newConfirmPassword.value
      &&
      (this.chagePasswordForm.controls.newConfirmPassword.value !== null &&
        this.chagePasswordForm.controls.newConfirmPassword.value !== '' &&
        this.chagePasswordForm.controls.newConfirmPassword.value !== undefined)) {
      this.formMessageTextColor = 'form-errors';
      const error = {
        general: [
          {
            message: this.commonService.getLabel('error_new_confirm_password_not_match')
          }
        ]
      };
      this.errorBindingService.onAPIValidationError(error, this.changePasswordErrors);
      this.validForm = false;
    } else {

      this.formMessageTextColor = 'form-success';
      const error = {
        general: [
          {
            message: null
          }
        ]
      };
      this.errorBindingService.onAPIValidationError(error, this.changePasswordErrors);
      this.validForm = true;

    }
  }

  requestData() {
    return {
      oldPassword: this.chagePasswordForm.controls.oldPassword.value,
      newPassword: this.chagePasswordForm.controls.newPassword.value,
    };
  }

  tooglePasswordType() {
    if (this.passwordType === 'password') {
      this.passwordType = 'text';
    } else {
      this.passwordType = 'password';
    }
  }


  doChangePassword() {
    const data = this.requestData();
    const c = this;
    this.signOutService.passwordChange(JSON.stringify(data)).subscribe(results => {
      if (results.general !== null && results.general !== undefined) {
        results.general.forEach(element => {
          this.errorMessage = [];
          this.errorMessage.push({ severity: 'success', summary: 'Success', detail: element.message });
        });
        setTimeout(() => {
          this.chagePasswordForm.reset();
          this.isShowChangePassword.emit(false);
        }, 2000);
      }
    }, (error) => {
      this.formMessageTextColor = 'form-errors';
      this.errorBindingService.onAPIValidationError(error, this.changePasswordErrors);
    });
  }

  onCancel() {
    this.chagePasswordForm.reset();
    this.isShowChangePassword.emit(false);
    const error = {
      general: [
        {
          message: ''
        }
      ]
    };
    this.errorBindingService.onAPIValidationError(error, this.changePasswordErrors);
  }

}
