import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from '../../../shared/app.settings';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Message } from 'primeng/components/common/api';
import { RouteRate } from './../../../corporates/models/route-rate-model';
import { ErrorBindingService } from '../../../shared/services/error-binding.service';
import { DestinationsModel } from './../../../corporates/models/destinations-models';
import { EditableRoutesModel } from './../../../hotels/models/editable-routes-model';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';

@Component({
  selector: 'app-edit-partner-routes',
  templateUrl: './edit-partner-routes.component.html'
})
export class EditPartnerRoutesComponent implements OnInit {


  @Output() isRoutePopCancel: EventEmitter<any> = new EventEmitter();
  @Output() isRoutePopSave: EventEmitter<any> = new EventEmitter();
  routeId = null;
  partnerId = null;
  Fare;
  values = [{
    fareLabel: 'Fare',
    fareformControlName: 'fare',
    destinationsName: '',
    activated: false
  }];
  routePackagePartnerDestinations: RouteRate[] = [];
  message: Message[] = [];
  packagesModel: EditableRoutesModel = null;
  destCount = 0;
  errorMessage: Message[] = [];
  editRoutesForm = new FormGroup({
  });
  routeApiError = [{
    rate: false,
    general: false,
  }];
  Save;
  Cancel;

  distanceValidation(control: FormControl) {
    const valInt = parseInt(control.value, 10);
    return (valInt >= AppSettings.MIN_DISTANCE && valInt <= AppSettings.MAX_DISTANCE) ? null : {
      distanceValidation: true
    };
  }
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private errorBindingService: ErrorBindingService,
    private commonBindingDataService: CommonBindingDataService
  ) { }

  ngOnInit() {
    this.Save = this.commonBindingDataService.getLabel('label_save');
    this.Cancel = this.commonBindingDataService.getLabel('label_cancel');
    this.Fare = this.commonBindingDataService.getLabel('label_fare');
    this.values.splice(1, this.values.length);
    this.editRoutesForm = this.formBuilder.group({
    });
    this.editRoutesForm.addControl(this.values[0].fareformControlName,
      new FormControl('', [Validators.required, Validators.pattern(AppSettings.FEE_PATTERN)]));
  }
  bindData(data, partnerId) {
    this.commonBindingDataService.getRoutingDetails(data.id, partnerId).subscribe((results) => {
      this.routeDataBinding(results);
    }, (error) => {
      this.errorBindingService.onAPIValidationError(error, this.routeApiError);
    });
    this.routeId = data.routePackgeDestinationId;
    this.partnerId = partnerId;
  }

  routeDataBinding(results) {
    this.packagesModel = new EditableRoutesModel(results);
    for (let i = 0; i < results.routePackagesDestinations.length; i++) {
      const destination = new DestinationsModel(results.routePackagesDestinations[i]);
      if (i > 0) {
        this.addDestination(destination);

      } else {
        this.editRoutesForm.controls[this.values[i].fareformControlName].setValue(destination.routeRate);
        this.values[i].destinationsName = destination.destinationLocationName;
        this.values[i].activated = destination.activated;
      }
    }
  }

  addDestination(data?) {
    this.destCount++;
    this.values.push({
      fareLabel: 'Fare ' + this.destCount,
      fareformControlName: 'fare' + this.destCount,
      destinationsName: data.destinationLocationName,
      activated: data.activated
    });
    this.editRoutesForm.addControl(this.values[this.values.length - 1].fareformControlName, new FormControl('', []));

    if (data !== null) {
      this.editRoutesForm.controls[this.values[this.values.length - 1].fareformControlName].setValue(data.routeRate);

    }
  }

  editRoutePackages() {
    for (let i = 0; i < this.values.length; i++) {
      this.routePackagePartnerDestinations.push(new RouteRate({
        routeRate: parseInt(this.editRoutesForm.controls[this.values[i].fareformControlName].value, 10),
        partnerId: this.partnerId,
        routePackgeDestinationId: this.packagesModel.routePackagesDestinations[i].id
      }));
    }
    const data = {
      routePackagePartnerDestinations: this.routePackagePartnerDestinations
    };
    this.commonBindingDataService.setRoute(data, this.partnerId)
      .subscribe((results) => {
        this.values.splice(1, this.values.length);
        this.editRoutesForm.reset();
        this.isRoutePopSave.emit(results);
      }, (error) => {
        this.errorBindingService.onAPIValidationError(error, this.routeApiError);
      });
  }

  cancel() {
    this.values.splice(1, this.values.length);
    this.editRoutesForm.reset();
    this.isRoutePopCancel.emit(false);
  }
}

