import { Component, OnInit } from '@angular/core';
import { CommonBindingDataService } from '../../modules/shared/services/common-binding-data.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Message, ConfirmationService } from 'primeng/components/common/api';
import { AppSettings } from 'app/modules/shared/app.settings';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-authorized-pending-payment',
  templateUrl: './authorized-pending-payment.component.html',
  styleUrls: ['./authorized-pending-payment.component.scss']
})
export class AuthorizedPendingPaymentComponent implements OnInit {

  pendingPaymentForm: FormGroup;
  message: Message[] = [];
  authorizationKey;
  cardNumber;
  tourId;
  passengerId;

  constructor(
    private commonBindingDataService: CommonBindingDataService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,

  ) { }

  ngOnInit() {

    this.pendingPaymentForm = this.formBuilder.group({
      cardNumber: ['', [
        // Validators.required,
        // Validators.minLength(1),
        // Validators.maxLength(50),
        // Validators.pattern(AppSettings.CARD_NUMBER_PATTERN)
      ]],
      amount: ['', [
        // Validators.required,
        // Validators.minLength(1),
        // Validators.maxLength(5),
        //Validators.pattern(AppSettings.AMOUNT_PATTERN)
      ]],
      cardCode: ['', [
        Validators.required,
        // Validators.minLength(1),
        Validators.maxLength(4),
        // Validators.pattern(AppSettings.NAME_PATTERN)
      ]],
  
    
    
    });

    this.authorizationKey = this.route.snapshot.queryParams['key'];
    this.cardNumber = this.route.snapshot.queryParams['card-number'];
    // this.pendingPaymentForm.controls.cardNumber.setValue(this.cardNumber);
    this.getCardDetails();
  }


  getCardDetails() {
    this.commonBindingDataService.getAuthorizedPendingCardDetails(this.authorizationKey).subscribe((result) => {
      this.cardNumber = result.cardNumber;
      this.tourId = result.id;
      this.passengerId = result.passengerId;
      this.pendingPaymentForm.controls.cardNumber.setValue(result.pFirstName + ' ' +result.pLastName);
      this.pendingPaymentForm.controls.amount.setValue(result.grandTotalString);

    }, (reason) => {  
      this.showMessage('Error',reason.general[0].message);

    });
  }
  
  addNow() {
    const data={
      "tourId":this.tourId,
      "tenantId":"",
      "passengerId":this.passengerId,
      "cardCode": (this.pendingPaymentForm.controls.cardCode.value).replace(/\s/g, ""),

    };
    this.commonBindingDataService.pendingPayment(data,this.authorizationKey).subscribe((result) => {
      this.showMessage('Success',result.general[0].message);
      this.pendingPaymentForm.reset();
      setTimeout(() => {
      this.router.navigate(['authorize-dot-net/success-page']);
    }, 2000);


    }, (reason) => {  
      this.showMessage('Error',reason.general[0].message);

    });
  }

 
  showMessage(severity, message) {
    this.message = [];
    this.message.push({ severity: severity.toLowerCase(), summary: severity, detail: message });
  }

}
